<template>
  <div class="" v-if="isLoaded">
    <student-header />
    <div class="bottom-margin pt-5">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="user-splash"></div>
              <div class="modal-content">
                <div class="modal-body">
                  <h1>EXCELERATE CAREER MATCHING</h1>
                  <p class="container-narrow mx-auto">
                    Share my Excelerate profile with companies and recruiters
                    looking for talent like me
                  </p>
                  <div
                    class="form-group custom-control custom-switch input-container d-flex align-items-center justify-content-center"
                  >
                    <p class="purple-color">Activate career matching</p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        v-model="userDetails.careerMatching"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between button_bottom"
                  >
                    <router-link
                      class="btn btn-link mt-4"
                      to="/user/student-talent/industry"
                      ><i class="fas fa-chevron-left"></i> Back</router-link
                    >
                    <button
                      class="btn btn-primary w-200 mt-4 Talent-ActivateExcelerateCareerMatching-Next"
                      @click="handleCareerMatching"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StudentHeader from '../../components/student/StudentHeader.vue';
export default {
  components: { StudentHeader },
  data() {
    return {
      userDetails: null,
    };
  },
  computed: {
    ...mapState(['user']),
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.userDetails = userInfo;
      }
      return true;
    },
  },
  methods: {
    handleCareerMatching() {
      this.$store.dispatch('updateUserDetails', this.userDetails);
      if(this.userDetails.careerMatching) {
        this.$router.push('/user/student-talent/complete');
      }else {
        this.$router.push('/');
      }
      
    },
  },
};
</script>

<style scoped>
.modal-mask {
  text-align: center;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  max-width: 550px !important;
}
.modal-content {
  color: black;
  max-width: 600px;
  padding: 3%;
  border-radius: 10px;
}
.content-splash {
 color: white!important;
  background: transparent linear-gradient(270deg, #BC1E73 0%, #5A2871 100%) 0% 0% no-repeat padding-box;

}
.modal-sub-header {
  font-size: 24px !important;
}
.btn-white {
  background-color: #fff;
  color: #5a2871;
}
.bottom-margin {
  margin-bottom: 130px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3aa948;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.custom-switch p {
  margin-bottom: 0;
  font-weight: 500 !important;
  color: #512871 !important;
  margin: 0px 0px 7px !important;
}
.custom-switch {
  column-gap: 10px;
}
@media(max-width: 767px) {
      .modal-content{
      border: none;
    }
      .button_bottom{
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    margin: 0 auto;
    max-width: 100%;
  }
    .modal-body h1 {
    margin-bottom: 50px;
  }
    }
</style>
 <style >
    @media(max-width: 767px) {
      #user-pages{
      background: #fff;
    min-height: 100vh !important;
    }
    }
  </style>