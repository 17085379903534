import { render, staticRenderFns } from "./studentRegisterTalentMatching.vue?vue&type=template&id=53b03caa&scoped=true&"
import script from "./studentRegisterTalentMatching.vue?vue&type=script&lang=js&"
export * from "./studentRegisterTalentMatching.vue?vue&type=script&lang=js&"
import style0 from "./studentRegisterTalentMatching.vue?vue&type=style&index=0&id=53b03caa&scoped=true&lang=css&"
import style1 from "./studentRegisterTalentMatching.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b03caa",
  null
  
)

export default component.exports